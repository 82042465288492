<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')"  id="users" no-body>

    <div v-if="!addForm && !editForm" class="page-header">
      <b-row>
        <b-col v-if="$checkPermission($options.name, 'insert')"  lg="2">
          <b-button @click="add" variant="primary">Ավելացնել</b-button>
        </b-col>
      </b-row>
    </div>

    <template v-if="addForm">
      <Form @updated="addForm = false" :action="'add'" :form="form" :user_types="user_types" :all_types="all_types"/>
    </template>

    <template v-else-if="editForm">
      <Form @updated="editForm = false" :action="'edit'" :form="form" :user_types="user_types" :all_types="all_types"/>
    </template>

    <template v-else>
      <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
      >
        <template #cell(type)="data">
          {{ typeName(data.value) }}
        </template>
        <template #cell(actions)="data">
          <fa-icon v-if="$checkPermission($options.name, 'update')"  @click="changeStatus(data.item)" icon="power-off"
                   :class="'table-action-icon item-status status-' + data.item.status"/>
          <fa-icon v-if="$checkPermission($options.name, 'update')"  @click="edit(data.item)" icon="edit" class="table-action-icon accent"/>
          <fa-icon v-if="$checkPermission($options.name, 'delete')"  @click="deleteItem(data.item)" icon="trash-alt" class="table-action-icon danger"/>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
            :label="'Գտնվել է ' + totalRows + ' տող'"
            label-cols="3"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
        >
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

    </template>



  </b-card-code>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import usersModule from '@/store/users/usersModule'
import vSelect from 'vue-select'
import { email, required } from '@validations'

import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import store from '@/store'
import Form from '@/views/pages/users/TechServiceOwnerForm'

export default {
  name: 'tech-service-owner-users',
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Form
  },
  data() {
    return {
      USERS_STORE_MODULE_NAME: 'usersModule',
      required,
      email,
      perPage: 20,
      pageOptions: [this.perPage],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Անուն',
        },
        {
          key: 'type',
          label: 'Խումբ',
        },
        {
          key: 'email',
          label: 'Էլ․ փոստ',
        },
        {
          key: 'actions',
          label: 'Կարգավիճակ',
          class: 'text-center width-10-per px-0',
        },
      ],
      addForm: false,
      editForm: false,
      formFields: {
        name: '',
        username: '',
        email: '',
        password: '',
        type: null,
        pages: [],
        permissions: [],
        companies: [],
        company: null,
        showCalendar: true,
        showPrint: true,
      },
      form: {}
    }
  },
  computed: {

    items() {
      return this.$store.state[this.USERS_STORE_MODULE_NAME].users
    },

    user_types() {
      return this.$store.state[this.USERS_STORE_MODULE_NAME].user_types
    },

    all_types() {
      const types = []
      this.user_types.map((item) => {
        let temp = {
          id: item.id,
          label: this.types[item.label]
        }
        types.push(temp)
      })
      return types
    },

    types() {
      return {
        ADMIN: 'Ադմինիստրատոր',
        TECHNICAL_SERVICE_OWNER: 'Կայանի ղեկավար',
        TECHNICAL_SERVICE_OPERATOR: 'Կայանի օպերատոր',
        TECHNICAL_SERVICE_VIEWER: 'Կայանի դիտորդ',
        TECHNICAL_SERVICE_MECHANIC: 'Կայանի հսկիչ',
      }
    },

    typeName() {
      return (id) => {
        let name = ''
        const index = this.user_types.findIndex((i) => i.id === id)
        if (index >= 0) {
          name = this.types[this.user_types[index].label]
        }
        return name
      }
    },

    totalRows() {
      return this.items.length
    },

    modal_title() {
      return (this.addForm) ? 'Ավելացնել' : 'Խմբագրել'
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
    },
    validateForm() {
      return this.form.name === ''
    },
    pages: {
      get () {
        return this.$store.state[this.USERS_STORE_MODULE_NAME].pages
      },
      set (data) {
        this.$store.commit(this.USERS_STORE_MODULE_NAME + '/SET_PAGES', data)
      }
    },
  },
  mounted() {
    // Set the initial number of items

  },
  async created() {
    if (!store.hasModule(this.USERS_STORE_MODULE_NAME)) store.registerModule(this.USERS_STORE_MODULE_NAME, usersModule)
    this.getUserTypes()
    this.getUsers()
    this.getCompanies()
  },
  methods: {

    getUserTypes() {
      this.$store.dispatch(this.USERS_STORE_MODULE_NAME + '/getUserTypes')
    },
    getUsers() {
      this.$store.dispatch(this.USERS_STORE_MODULE_NAME + '/getUsers')
    },
    getCompanies() {
      this.$store.dispatch(this.USERS_STORE_MODULE_NAME + '/getCompanies')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    add() {
      this.editForm = false
      this.addForm = true
      this.form = this.$store.getters['getForm'](true)(this.formFields)(null)
    },
    edit(item) {
      this.form.pages = []
      const index = this.user_types.findIndex((i) => i.id === item.type)
      if (index >= 0) {
        const pages = this.user_types[index].pages
        const result = []
        pages.map((item) => {
          let temp = {
            id: item.id,
            title: item.title
          }
          result.push(temp)
        })
        this.pages = result
      }
      this.form = this.$store.getters['getForm'](false)(this.formFields)(item)
      this.addForm = false
      this.editForm = true
    },
    deleteItem(item) {
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք հեռացնել օգտատիրոջը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.USERS_STORE_MODULE_NAME + '/deleteUser', item.id)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: 'Հեռացվել է!',
                      text: 'Օգատտերը հաջողությամբ հեռացվեց',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },
    changeStatus(item) {
      const status = !item.status
      const action = (status) ? 'ակտիվացնել' : 'պասիվացնել'
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք ' + action + ' օգտատիրոջը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              item.status = status
              this.$store.dispatch(this.USERS_STORE_MODULE_NAME + '/editUser', item)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: '',
                      text: 'Փոփոխությունները պահպանվեցին',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#users {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 15rem);
    min-height: calc(var(--vh, 1vh) * 100 - 15rem);
  }
}
</style>
