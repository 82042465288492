var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"userForm"}},[(_vm.action === 'add' && _vm.$checkPermission('tech-service-owner-users', 'insert'))?_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.addUser}},[_vm._v(" Ավելացնել ")]):_vm._e(),(_vm.action === 'edit' && _vm.$checkPermission('tech-service-owner-users', 'update'))?_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.editUser}},[_vm._v(" Խմբագրել ")]):_vm._e(),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.$emit('updated')}}},[_vm._v(" Վերադառնալ ")]),_c('b-form-checkbox',{staticClass:"custom-control-success ml-2",attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.form.showCalendar),callback:function ($$v) {_vm.$set(_vm.form, "showCalendar", $$v)},expression:"form.showCalendar"}},[_vm._v(" Ցուցադրել ամսաթիվը ")]),_c('b-form-checkbox',{staticClass:"custom-control-success ml-2",attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.form.showPrint),callback:function ($$v) {_vm.$set(_vm.form, "showPrint", $$v)},expression:"form.showPrint"}},[_vm._v(" Թույլատրել տպել ")]),_c('validation-observer',{ref:"userValidation"},[_c('b-form',{staticClass:"form",attrs:{"autocomplete":"off"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Խումբ","label-for":"user-type"}},[_c('v-select',{class:{'is-invalid': !!errors.length},attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.all_types,"reduce":function (prefix) { return prefix.id; },"clearable":false,"autocomplete":"new-fuckable-select","input-id":"user-status"},on:{"input":_vm.changeType},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Անուն","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Էլ․ փոստ","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"full-email","type":"email","state":errors.length > 0 ? false:null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[(_vm.action === 'add')?_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Գաղտնաբառ","label-for":"password"}},[_c('b-form-input',{attrs:{"id":"password","type":"password","state":errors.length > 0 ? false:null},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)]}}],null,false,1325373120)}):_c('b-form-group',{attrs:{"label":"Գաղտնաբառ","label-for":"password_edit"}},[_c('b-form-input',{attrs:{"id":"password_edit","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}}),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Pages","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Էջեր","label-for":"user-pages"}},[_c('v-select',{class:(!!errors.length) ? 'select-size-lg is-invalid' : 'select-size-lg',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.pages,"reduce":function (prefix) { return prefix.id; },"multiple":""},on:{"input":_vm.changePage},model:{value:(_vm.form.pages),callback:function ($$v) {_vm.$set(_vm.form, "pages", $$v)},expression:"form.pages"}})],1)]}}])})],1),(_vm.selectedUserType === 'TECHNICAL_SERVICE_OWNER' )?_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Companies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Կազմակերպություններ","label-for":"user-pages"}},[_c('v-select',{class:(!!errors.length) ? 'select-size-lg is-invalid' : 'select-size-lg',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.companies,"reduce":function (prefix) { return prefix.id; },"multiple":""},model:{value:(_vm.form.companies),callback:function ($$v) {_vm.$set(_vm.form, "companies", $$v)},expression:"form.companies"}})],1)]}}],null,false,1449376206)})],1):_vm._e(),(_vm.selectedUserType === 'TECHNICAL_SERVICE_OPERATOR' || _vm.selectedUserType === 'TECHNICAL_SERVICE_MECHANIC' || _vm.selectedUserType === 'TECHNICAL_SERVICE_VIEWER' )?_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Companies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Կազմակերպություններ","label-for":"user-pages"}},[_c('v-select',{class:(!!errors.length) ? 'select-size-lg is-invalid' : 'select-size-lg',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.companies,"reduce":function (prefix) { return prefix.id; }},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})],1)]}}],null,false,238435950)})],1):_vm._e()],1)],1)],1),_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Permission")])],1)],1),_c('b-table',{staticClass:"mb-0",attrs:{"responsive":"","fields":_vm.fields,"striped":"","items":_vm.form.permissions},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell()",fn:function(data){return [_c('b-form-checkbox',{attrs:{"checked":data.value},on:{"input":function($event){return _vm.changePermission(data)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }